
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "fa7a2b31-9a9a-4ac7-a6d8-506c8ebad54a"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/apps/app/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
